.Pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Pagination-container-center {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Pagination-next {
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 23px solid #D9D9D9;
  margin-left: 10px;
}

.Pagination-previous {
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent; 
  border-right:23px solid #D9D9D9; 
  margin-right: 10px;
}
.Pagination-next.disabled, .Pagination-previous.disabled {
  opacity: 0.4
}

.Pagination-next:not(.disabled):hover {
  border-left-color: #26B2ED;
  cursor: pointer;
}
.Pagination-previous:not(.disabled):hover {
  border-right-color: #26B2ED;
  cursor: pointer;
}

.Pagination-page {
  cursor: pointer;
  background: #D9D9D9;
  display: inline-block;
  margin: 3px;
  width: 10px; 
  height: 10px; 
  border-radius: 5px;
}
.Pagination-page.active, .Pagination-page:hover {
  background: #26B2ED
}