.TextAssetLine {
  display: flex;
  align-items: center;
}

.TextAssetLine--DELETE, .TextAssetLine--DELETE input[type="text"]:disabled {
  background: white;
  border-color: #DB1B4E
}

.TextAssetLine--CREATE {
  background: #F6FAEF;
}

.TextAssetLine--DELETE {
  background: #F8E9ED;
}

.TextAssetLine-Missing {
  color: #db1b4e;
  cursor: not-allowed;
}

.TextAssetLine-Missing input {
  color: #db1b4e !important;
  background: #ffeeee !important;
  border-color: #db1b4e !important;
}
