.statsCardGrid {
    grid-gap: 24px;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    margin-bottom: 48px;
    padding-top: 48px;
}

.tabs {
    margin-bottom: 1em;
}

.tabs>ul.dropdown-ready {
    overflow: visible;
}
.tabs>ul {
    border-bottom: 1px solid #b7b7b7;
    height: 37px;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 3px 0 0;
    position: relative;
    z-index: 8;
}

.tabs>ul>li {
    float: left;
    margin-right: -1px;
    position: relative;
    white-space: nowrap;
}

.tabs>ul>li.active:before, .tabs>ul>li.ui-tabs-active:before {
    background: #84b73b;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    top: -3px;
    width: 100%;
}

.tabs>ul>li.active>a, .tabs>ul>li.ui-tabs-active>a {
    background-color: #fff;
    height: 34px;
}
.tabs>ul>li>a {
    background-color: #f7f7f7;
    border: 1px solid #b7b7b7;
    border-bottom: none;
    color: #000;
    display: block;
    font-size: .75rem;
    height: 33px;
    padding: 8px 18px 0;
    text-decoration: none;
    white-space: nowrap;
}

.std-tabs {
    margin-bottom: 1em;
}

.std-tabs>ul {
    margin: 0;
    overflow: visible;
    padding: 0;
    position: relative;
}

.std-tabs>ul>li {
    float: left;
    list-style: none;
    position: relative;
    white-space: nowrap;
}
.list-columns-5>li {
    width: 20%;
}
.list-columns-2>li, .list-columns-3>li, .list-columns-4>li, .list-columns-5>li {
    float: left;
}

.tabs-dashboard.std-tabs>ul>li.ui-state-active>a {
    line-height: 21px;
    margin-top: 0;
    padding-bottom: 16px;
    padding-top: 16px;
    top: 1px;
}
.tabs-dashboard.std-tabs>ul>li.ui-state-active>a {
    top: 5px !important;
}
.tabs-dashboard.std-tabs>ul>li>a {
    margin-top: 12.6px;
    padding-left: 5px;
    padding-right: 5px;
}
.std-tabs>ul>li:first-child>a {
    border-left-style: solid;
}
.tabs-dashboard .active a, .tabs-dashboard .ui-state-active a, .tabs-dashboard>ul>li a {
    line-height: inherit;
}
.std-tabs .active>a, .std-tabs .ui-state-active>a {
    background-color: #14b1ef;
    border-bottom-style: none;
    border-color: #14b1ef;
    border-right-style: solid;
    color: #fff;
    margin-top: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;
    top: 1px;
}
.std-tabs>ul>li>a {
    background-color: #fff;
    border: 1px solid #b7b7b7;
    border-left-style: none;
    color: #000;
    float: left;
    font-size: .75rem;
    line-height: 1;
    margin-top: .5rem;
    padding: .75rem 3.125rem;
    text-align: center;
    white-space: nowrap;
    width: 100%;
}

#css .tab-content-main {
    display: block;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5rem;
}

.tab-content-header+.tab-content-main {
    margin-top: .5rem;
}

.tab-content-additional {
    display: block;
    line-height: 1rem;
    margin-top: .25rem;
    min-height: 1rem;
}

.std-tabs .active>a:after, .std-tabs .ui-state-active>a:after {
    border: 7px solid rgba(20, 177, 239, 0);
    border-top-color: #14b1ef;
    content: "";
    left: 50%;
    margin-left: -7px;
    position: absolute;
    top: 100%;
    z-index: 1;
}

.std-tabs>ul>li>a:hover {
    background-color: #14b1ef;
    border-color: #14b1ef;
    color: #fff;
}

.std-tabs>ul>li>a:hover {
    text-decoration: none;
}

ul.legend>li {
    display: inline-block;
    list-style: none inside;
    margin-right: 20px;
    position: relative;
}

ul.legend>li>span {
    display: block;
    height: 10px;
    left: -15px;
    position: absolute;
    top: 4px;
    width: 10px;
}