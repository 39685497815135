.UploadImage-wrapper {
  position: relative;
  width: 200px;
  height: 200px;
  display: block;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.UploadImage-wrapper:hover {
  background: #e4e4e4;
}

.UploadImage-missing, .UploadImage-missing:hover {
  cursor: not-allowed;
  border: 1px solid #db1b4e;
  color: #db1b4e;
  background-color: #FFeeee;
}

.dragover {
  opacity: 0.8;
}

.UploadImage-input {
  opacity: 0;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.UploadImage-loader {
  height: 100px;
  display: block;
  position: relative;
  width: 100px;
}