.fieldset {
    border: 1px solid #E9E9E9;
    padding: 2em;
    position: relative;
    margin-top: 10px;
}

.fieldset-legend {
    position: absolute;
    top: -9px;
    left: 16px;
    background: white;
    padding: 0 8px;
    color: #37373D;
    font-size: 1em;
}