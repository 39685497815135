/* General */
body {
  height:auto;
}

p, .perexBox {
  white-space: pre-line;
}

.perexBox.info {
  background-color: #f2fbff;
  color: #139fd7;
}

@media (max-width: 1000px) {
  p, .perexBox {
    white-space: normal;
  }
}

.ImageWrapper--Preview embed, .ImageWrapper--SQUARE_MARKETING_IMAGE embed, .ImageWrapper--MARKETING_IMAGE embed{
  transform-origin: top left;
}
.ImageWrapper--Preview {
  overflow: hidden;
}

.ImageWrapper--SQUARE_MARKETING_IMAGE {
  max-width: 200px !important;
  max-height: 200px !important;
  overflow: hidden;
}

.ImageWrapper--MARKETING_IMAGE {
  max-width: 286px !important;
  max-height: 150px !important;
  overflow: hidden;
}

.ImageWrapper--SQUARE_MARKETING_IMAGE .ImageWrapper--Preview {
  width: 200px;
  height: 200px;
}
.ImageWrapper--MARKETING_IMAGE .ImageWrapper--Preview {
  width: 286px;
  height: 150px;
}

.AuditLog-image {
}

.AuditLog-image .ImageWrapper--SQUARE_MARKETING_IMAGE {
  max-width: 100px !important;
  max-height: 100px !important;
}

.AuditLog-image .ImageWrapper--MARKETING_IMAGE {
  max-width: 143px !important;
  max-height: 74px !important;
}


.ImageWrapper--Preview {
	background: rgb(237,237,237);
	background: linear-gradient(0deg, rgba(237,237,237,1) 0%, rgba(170,170,170,1) 100%);
}

.btn.btn-muted path {
  fill: black;
}
.btn.btn-muted:hover path {
  fill: white;
}

@keyframes loadingAnimation {
	0%,
	50%,
	100% {
		opacity: 0.5;
	}

	25%,
	75% {
		opacity: 0.2;
	}
}

.loadingAnimation {
  animation: loadingAnimation 2s ease 0s infinite normal forwards;
}

.Accordion { 
  display: grid;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  padding-top: 0px;
}
.Accordion > div {
  overflow: hidden;
}
.Accordion--closed { 
  grid-template-rows: 0fr;
}
.Accordion--opened { 
  grid-template-rows: 1fr;
  padding-top: 20px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

html {
  height: auto !important;
}
body {
  height: auto !important;
}

.shoptet-icon.icon-size-96 {
  font-size: 96px;
  width: 96px;
  height: 96px;
}
.shoptet-icon.icon-size-96:before {
  font-size: 96px;
  width: 96px;
  height: 96px;
}
.shoptet-icon.shoptet-icon-color-error:before {
  color: #db1b4e !important;
}
.shoptet-icon.shoptet-icon-color-warning:before {
  color: #FFAE00 !important;
}

.v2form .addon {
  display: inline-block;
  margin-top: 4px;
  margin-bottom: 4px;
}
.v2form .addon .btn {
  margin: 0px;
}

.tabs-dashboard.std-tabs > ul > li.ui-state-active > a {
  top: 5px !important;
}
.v2form--alignStart .v2FormField {
  align-items: flex-start;
}
.v2form--alignStart .v2FormField__label {
  margin-top: 5px;
}
.v2FormField input[type=checkbox]:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.with-short-label .v2FormField__label {
  width: auto!important;
}

.TextAssets.v2form .v2FormField__label, .TextSelection .v2FormField__label {
  width: 0;
  margin-left: -16px;
}

.v2FormField__radio:disabled {
  border-color: #cfcfcf !important;
  background-color: #efefef !important;
}
.v2FormField__radio:checked:disabled {
  border-color: #cfcfcf !important;
}
.v2FormField__radio:checked:disabled:before {
  background-color: #dfdfdf !important;
}

.show-on-mobile {
  display: none;
}
.show-on-destop {
  display: block;
}

.addon {
  margin-left: 10px;
}

.SuggestionStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SuggestionStatusMessage {
  display: flex;

  span {
    margin-left: 3px;
  }

  .shoptet-icon {
    width: 16px;
    height: 16px;
    margin-left: 0;

    &:before {
      font-size: 16px;
    }
  }

  svg, path {
    fill: #9DCA57;
  }
}

.SuggestionStatusCounter {
  margin-right: 28px;
}

@media (max-width: 1100px) {
  .show-on-mobile {
    display: block;
  }
  .show-on-destop {
    display: none;
  }
}

@media (max-width: 800px) {

  .flexWrapUnder800 {
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {

  #css .tab-content-main {
    font-size: 0.5rem !important;
  }
  .ProgressStepper-container {
    height: 80px;
  }
  .AdPreview-crop-on-mobile {
    width: 335px;
    overflow: hidden;
    height: 567px;
    border: 1px solid #D8DBDF;
  }

  .AdPreview-crop-on-mobile > .AdPreview-crop-on-mobile-content {
    left: -21px;
    position: relative;
    top: -51px;
  }

  .ui-tabs-nav {
    display: flex;
    overflow-x: auto !important;
  }
  .tabs>ul>li {
    float: none;
  }

  .v2form .v2FormField__label {
    text-align: left !important;
    width: 100% !important;
  }
  .v2FormField {
    flex-wrap: wrap;
  }
  .v2FormField__input button {
    margin-top: 8px !important;
    margin-left: 0px !important;
  }
  .addon {
    display: block;
    margin-left: 0px;
  }
  .addonWrapper {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .addonWrapper .addon {
    margin-top: 10px;
  }

  .SuggestionExampletText {
    margin-left: 0px !important;
  }

  .reactTooltip__trigger {
    display: none;
  }

  .ImageAssetLines-ButtonsToGenerate {
    flex-direction: column;
  }
  .ImageAssetLines-ButtonsToGenerate > button {
    width: 100%;
  }

  .ImageAssetLines-imagesWrapper {
    justify-content: center;
  }
}


.statsCard__icon--impressions:before {
  content: "\e90f"
}

.statsCard__icon--clicks:before {
  content: "\e935"
}

.monthly-budget .v2FormField__error {
  max-width: 270px !important;
  white-space: normal !important;
}

.btn-fullwidth-container .btn {
  width: 100%;
}

.text-asset-line {
  display: flex;
  align-items: center;
}

.text-asset-line input[type="text"] {
  width: 90ch !important;
}

.buttons-addon {
  margin: 4px 5px;
  display: flex;
  align-items: center;
}

.buttons-addon .btn {
  margin: 0 5px !important;
}

.edit-btn-addon {
  display: inline-block;
  margin-left: 5px;
}

.edit-btn-addon .btn, .monthly-budget .btn {
  margin: 0 5px!important;
}

.monthly-budget .btn-action {
  margin-left: 10px!important;
}

.statsCardGrid {
  grid-gap: 24px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  margin-bottom: 48px;
  padding-top: 48px;
}

.tabs {
  margin-bottom: 1em;
}

.tabs>ul.dropdown-ready {
  overflow: visible;
}
.tabs>ul {
  border-bottom: 1px solid #b7b7b7;
  height: 37px;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 3px 0 0;
  position: relative;
  z-index: 8;
}

.tabs>ul>li {
  float: left;
  margin-right: -1px;
  position: relative;
  white-space: nowrap;
}

.tabs>ul>li.active:before, .tabs>ul>li.ui-tabs-active:before {
  background: #84b73b;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  top: -3px;
  width: 100%;
}

.tabs>ul>li.active>a, .tabs>ul>li.ui-tabs-active>a {
  background-color: #fff;
  height: 34px;
}
.tabs>ul>li>a {
  background-color: #f7f7f7;
  border: 1px solid #b7b7b7;
  border-bottom: none;
  color: #000;
  display: block;
  font-size: .75rem;
  height: 33px;
  padding: 8px 18px 0;
  text-decoration: none;
  white-space: nowrap;
}

.std-tabs {
  margin-bottom: 1em;
}

.std-tabs>ul {
  margin: 0;
  overflow: visible;
  padding: 0;
  position: relative;
}

std-tabs>ul>li {
  float: left;
  list-style: none;
  position: relative;
  white-space: nowrap;
}
.list-columns-5>li {
  width: 20%;
}
.list-columns-2>li, .list-columns-3>li, .list-columns-4>li, .list-columns-5>li {
  float: left;
}

.tabs-dashboard.std-tabs>ul>li.ui-state-active>a {
  line-height: 21px;
  margin-top: 0;
  padding-bottom: 16px;
  padding-top: 16px;
  top: 1px;
}
.tabs-dashboard.std-tabs>ul>li.ui-state-active>a {
  top: 5px !important;
}
.tabs-dashboard.std-tabs>ul>li>a {
  margin-top: 12.6px;
  padding-left: 5px;
  padding-right: 5px;
}
.std-tabs>ul>li:first-child>a {
  border-left-style: solid;
}
.tabs-dashboard .active a, .tabs-dashboard .ui-state-active a, .tabs-dashboard>ul>li a {
  line-height: inherit;
}
.std-tabs .active>a, .std-tabs .ui-state-active>a {
  background-color: #14b1ef;
  border-bottom-style: none;
  border-color: #14b1ef;
  border-right-style: solid;
  color: #fff;
  margin-top: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
  top: 1px;
}
.std-tabs>ul>li>a {
  background-color: #fff;
  border: 1px solid #b7b7b7;
  border-left-style: none;
  color: #000;
  float: left;
  font-size: .75rem;
  line-height: 1;
  margin-top: .5rem;
  padding: .75rem 3.125rem;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

#css .tab-content-main {
  display: block;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.tab-content-header+.tab-content-main {
  margin-top: .5rem;
}

.tab-content-additional {
  display: block;
  line-height: 1rem;
  margin-top: .25rem;
  min-height: 1rem;
}

.std-tabs .active>a:after, .std-tabs .ui-state-active>a:after {
  border: 7px solid rgba(20, 177, 239, 0);
  border-top-color: #14b1ef;
  content: "";
  left: 50%;
  margin-left: -7px;
  position: absolute;
  top: 100%;
  z-index: 1;
}

.std-tabs>ul>li>a:hover {
  background-color: #14b1ef;
  border-color: #14b1ef;
  color: #fff;
}

.std-tabs>ul>li>a:hover {
  text-decoration: none;
}

.std-tabs>ul {
  list-style-type: none;
}

ul.legend>li {
  display: inline-block;
  list-style: none inside;
  margin-right: 20px;
  position: relative;
}

ul.legend>li>span {
  display: block;
  height: 10px;
  left: -15px;
  position: absolute;
  top: 4px;
  width: 10px;
}

.systemMessage {
  margin: 12px 6px !important;
}

.modal--position-top {
  .modal__wrapper {
    justify-content: flex-start;
    padding-top: 150px;
  }
}

.modal--position-bottom {
  .modal__wrapper {
    justify-content: flex-end;
    padding-bottom: 150px;
  }
}

.gmc-issue-link {
  color: #0754c6;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
}