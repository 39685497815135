.AdPreview-Menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.AdPreview-Menu li {
  min-width: 100px;
  text-align: center;
  display: inline-block;
  padding: 10px;
  cursor: pointer;
}
.AdPreview-Menu li img {
  width: 24px;
  display: block;
  margin: 0 auto 3px auto;
}
.AdPreview-Menu li.active, .AdPreview-Menu li.active:hover {
  background: white;
  color: #14B1EF;
}
.AdPreview-Menu li:hover {
  background: #eee
}

.AdPreview-Container {
  background: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  user-select: none;
}

.PlaPreview-headline {
  color: #3265cb;
  font-weight: 400;
  letter-spacing: -1.3px;
  position: absolute;
  height: 76px;
  top: 193px;
  left: 12px;
  right: 12px;
  font-size: 19px;
  line-height: 24px;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}