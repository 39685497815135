.ImageAssetLine {
  border: 1px solid #E9E9E9;
}

.ImageAssetLine--CREATE {
  position: relative;
  border-color: #9DCA57;
}

.ImageAssetLine--DELETE {
  position: relative;
  border-color: #DB1B4E;
}

.ImageAssetLine--CREATE:after, .ImageAssetLine--DELETE:after {
  position: absolute;
  inset: 0;
  content: ' ';
  opacity: 0.1;
  pointer-events: none;
}

.ImageAssetLine--CREATE:after {
  background: #9DCA57;
}

.ImageAssetLine--DELETE:after {
  background: #DB1B4E;
}

.ImageAssetLine--DELETE .actionButton, .ImageAssetLine--CREATE .actionButton, .ImageAssetLine--DELETE:hover .stateInfo, .ImageAssetLine--CREATE:hover .stateInfo {
  display: none;
}
.ImageAssetLine--DELETE:hover .actionButton, .ImageAssetLine--CREATE:hover .actionButton, .ImageAssetLine--DELETE .stateInfo, .ImageAssetLine--CREATE .stateInfo {
  display: block;
}
